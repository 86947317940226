<template>
  <div class="password_content" v-loading="loading">
    <div class="content">
      <MyForm :form="form" />
      <div class="toUpdataPass_box">
        <span class="edit_password_btn hand" @click="toUpdataPass">忘记密码?</span>
      </div>

      <div class="login_btn_box mb20">
        <el-button :disabled="!isSubmitLogin" class="login_btn" type="primary" @click="login">登 录
        </el-button>
        <el-button :disabled="!isSubmitLogin" class="login_btn ip_login" type="primary"
                   @click="ipLogin">IP 登
          录
        </el-button>
      </div>
      <p class="tac hand fs14" @click="toRegister">快速注册></p>
    </div>
    <Agreement ref="Agreement" :isLogin="isLogin" @checkChange="checkChange" />
    <div id="geetestBox"></div>
  </div>
</template>

<script>
import Agreement from './agreement.vue'
import '../geetest4/gt4'
import { loginBypassword } from '@/api/user'
import { mapMutations } from 'vuex'
import MyForm from './loginForm.vue'
export default {
  components: {
    Agreement,
    MyForm
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        phone: '',
        phoneError: '',
        password: '',
        passwordError: ''
      },
      ischeckbox: false,
      captchaInstance: {},
      loading: false
    }
  },
  methods: {
    ...mapMutations('user', ['updateToken']),
    ipLogin () {
      if (!this.$store.getters.userIpInfo?.partnerId) return this.$message.warning('当前 IP 网络未授权')
      this.login()
    },
    toUpdataPass () {
      this.$emit('toUpdataPass')
    },
    async loginService (validate) {
      try {
        this.loading = true
        this.$myCount('PC-账号登录')
        const res = await loginBypassword({
          phone: this.form.phone,
          passWord: this.form.password,
          registerFrom: 'PC-手机号密码注册',
          captchaId: validate.captcha_id,
          captchaOutput: validate.captcha_output,
          genTime: validate.gen_time,
          lotNumber: validate.lot_number,
          passToken: validate.pass_token,
          registerFromTag: sessionStorage.getItem('jumpSource') || ''
          // channel: 'pc'
        })
        if (res.data.type !== 1) {
          const list = res.data.list.map(item => ({
            ...item,
            phone: this.form.phone,
            password: this.form.password
          }))
          this.$emit('select', list)
        } else {
          this.updateToken(res.data.obj)
          window.location.reload()
          this.$closeLogin()
          this.loading = false
        }
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$myNotify.warning(error || '登录失败, 请重试')
      }
    },
    initGeetest4 () {
      window.initGeetest4({
        captchaId: 'b3b44b115ea5bab7eec6f341934b695b',
        product: 'bind'
      }, (captcha) => {
        // captcha为验证码实例
        captcha.appendTo('#geetestBox')
        this.captchaInstance = captcha
        captcha.onSuccess(() => {
          this.loginService(captcha.getValidate())
        })
      })
    },
    checkChange (type) {
      this.ischeckbox = type
    },
    toRegister () {
      this.$emit('toRegister')
    },
    login () {
      if (!this.ischeckbox) return this.$refs.Agreement.animation()
      this.captchaInstance.showCaptcha()
    }
  },
  computed: {
    isSubmitLogin () {
      return this.form.phone && !this.form.phoneError && this.form.password && !this.form.passwordError && /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/.test(this.form.password)
    }
  },
  mounted () {
    this.initGeetest4()
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 5px 50px 0;
  margin-bottom: 47px;
  .input_box {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    background-color: #f5f7fb;
    border-radius: 4px;
    /deep/ .el-input__inner {
      border: 0 !important;
      background-color: transparent;
    }
  }
  .err_text {
    position: absolute;
    left: 0;
    bottom: -17px;
    color: #f00;
  }
  .toUpdataPass_box {
    position: relative;
    width: 100%;
    height: 13px;
  }
  .edit_password_btn {
    position: absolute;
    right: 0;
    top: -15px;
    color: #979797;
  }
  .login_btn_box {
    display: grid;
    grid-template-columns: repeat(2, 140px);
    column-gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    .login_btn {
      width: 100%;
      height: 100%;
      font-weight: 600;
    }
    .ip_login {
      margin-left: 0;
      background-color: #f57058;
      border-color: #f57058;
      &:hover {
        background-color: #ff8b76;
        border-color: #ff8b76;
      }
    }
    .ip_login.is-disabled {
      background-color: #ffadbc;
      border-color: #ffadbc;
    }
  }
}
</style>
