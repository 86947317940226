import request from '@/utils/request.js'
import fwRequest from '@/utils/fwRequest'

const requestMap = {
  1: request,
  2: fwRequest
}
export const setApi = (pathMap, type = 1) => {
  const requestServe = requestMap[type]
  return Object.keys(pathMap).reduce((res, apiName) => {
    const isGet = apiName.indexOf('get') !== -1
    res[apiName] = (options, headers = {}) => requestServe({
      method: isGet ? 'GET' : 'POST',
      url: pathMap[apiName],
      data: isGet ? {} : options,
      params: isGet ? options : {},
      headers: headers
    })
    return res
  }, {})
}
