<template>
  <div class="short_msg">
    <div class="short_msg_content" v-loading="loading">
      <MyForm :form="form" />
      <div class="login_btn_box mb20">
        <el-button :disabled="!isSubmitLogin" class="login_btn" type="primary" @click="login">登 录
        </el-button>
        <el-button :disabled="!isSubmitLogin" class="login_btn ip_login" type="primary"
                   @click="ipLogin">IP 登
          录
        </el-button>
      </div>
      <p class="tac hand fs14" @click="toRegister">快速注册></p>
    </div>
    <p class="tac hand fs14 mb10">未注册手机号将自动注册并登录</p>
    <Agreement ref="Agreement" :isLogin="isLogin" @checkChange="checkChange" />
  </div>
</template>

<script>
import Agreement from './agreement.vue'
import { verifiCodeLogin } from '@/api/user'
import { mapMutations } from 'vuex'
import MyForm from './loginForm.vue'
export default {
  name: 'ShortMsg',
  components: {
    MyForm,
    Agreement
  },
  props: {
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ischeckbox: false,
      loading: false,
      form: {
        phone: '',
        phoneError: '',
        verifyCode: '',
        verifyCodeError: ''
      }
    }
  },
  methods: {
    ...mapMutations('user', ['updateToken']),
    ipLogin () {
      if (!this.$store.getters.userIpInfo?.partnerId) return this.$message.warning('当前 IP 网络未授权')
      this.login()
    },
    async login () {
      try {
        if (!this.ischeckbox) return this.$refs.Agreement.animation()
        this.loading = true
        this.$myCount('PC-验证码登录')
        const res = await verifiCodeLogin({
          phone: this.form.phone,
          verifyCode: this.form.verifyCode,
          registerFrom: 'PC-手机号验证码注册',
          registerFromTag: sessionStorage.getItem('jumpSource') || ''
          // channel: 'pc'
        })
        if (res.data.type !== 1) {
          const list = res.data.list.map(item => ({
            ...item,
            phone: this.form.phone,
            verifyCode: this.form.verifyCode
          }))
          this.$emit('select', list)
        } else {
          if (res.data.obj.isShowTrialVipTips) {
            this.$myCount({
              name: 'PC-验证码注册',
              successCallback: () => {
                this.updateToken(res.data.obj)
                this.loading = false
                this.$closeLogin()
                window.location.reload()
              }
            })
          } else {
            this.updateToken(res.data.obj)
            this.loading = false
            this.$closeLogin()
            window.location.reload()
          }
        }
      } catch (error) {
        this.loading = false
        this.$myNotify.warning('登录失败, 请重试')
      }
    },
    checkChange (type) {
      this.ischeckbox = type
    },
    toRegister () {
      this.$emit('toRegister')
    },
    init () {
      this.form = this.$options.data().form
      this.ischeckbox = false
      this.loading = false
      this.$refs.verifiCode && this.$refs.verifiCode.init()
    }
  },
  computed: {
    isSubmitLogin () {
      return this.form.phone && !this.form.phoneError && this.form.verifyCode && !this.form.verifyCodeError && this.form.verifyCode.length === 6
    }
  },
  created () {
  },
  watch: {
    isLogin (newVal) {
      if (!newVal) {
        this.init()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.short_msg {
  padding: 5px 0 0;
  .short_msg_content {
    padding: 0 50px;
    margin-bottom: 60px;
  }
  .input_box {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    background-color: #f5f7fb;
    border-radius: 4px;
    /deep/ .el-input__inner {
      border: 0 !important;
      background-color: transparent;
    }
  }
  .region {
    color: #979797;
    padding: 0 10px 0 14px;
  }
  .line {
    width: 1px;
    height: 16px;
    background-color: #979797;
    margin-right: 10px;
  }
  .phone_input {
    padding-right: 10px;
  }
  .err_text {
    position: absolute;
    left: 0;
    bottom: -17px;
    color: #f00;
  }

  .login_btn_box {
    display: grid;
    grid-template-columns: repeat(2, 140px);
    column-gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    .login_btn {
      width: 100%;
      height: 100%;
      font-weight: 600;
    }
    .ip_login {
      margin-left: 0;
      background-color: #f57058;
      border-color: #f57058;
      &:hover {
        background-color: #ff8b76;
        border-color: #ff8b76;
        // color: #49536245;
      }
    }
    .ip_login.is-disabled {
      background-color: #ffadbc;
      border-color: #ffadbc;
    }
  }
}
</style>
