<template>
  <div id="app" @click="hideSearchProposal" @mousedown="mousedown">
    <LogIn />
    <Poster />
    <UserInfoDialog />
    <div id="nav_box" class="index_top"
         :class="{ 'isBg': (isNavBg || isBg), 'home_fixed': $route.path === '/' }">
      <headComponent ref="headComponent"
                     v-if="!['/pdfDetails', '/404', '/authorize'].includes($route.path)"
                     :submitUserUseTime="submitUserUseTime" />
    </div>
    <heightSearch ref="heightSearch" v-if="SearchPages.includes($route.path)" @Jump="Jump" />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <footComponent
                   v-if="!['/pdfDetails', '/404', '/uploadVideo', '/aiModule', '/activityContent', '/authorize'].includes($route.path)" />
    <!-- <el-backtop :bottom="150" :right="20">
      <img src="@/assets/img/index/fhdb.png">
    </el-backtop> -->
    <EdgeIcons v-if="!['/pdfDetails', '/404'].includes($route.path)" />
    <!-- <PosterIcon /> -->
  </div>
</template>
<script>
import footComponent from './components/indexButtom/index.vue'
import heightSearch from './components/searchTitle/index.vue'
import headComponent from './components/indexTitle/index.vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { guard } from './router/permission.js'
import merge from 'webpack-merge'
import LogIn from '@/components/login/index.vue'
import { submitUserUseTime } from '@/api/user.js'
import UserInfoDialog from '@c/userInfoDialog/index.vue'
import Poster from '@c/poster/index.vue'
import EdgeIcons from '@c/edgeIcons/index.vue'
// import PosterIcon from '@c/poster/components/posterIcon.vue'
export default {
  components: {
    footComponent,
    heightSearch,
    headComponent,
    LogIn,
    Poster,
    // PosterIcon,
    UserInfoDialog,
    EdgeIcons
  },
  data () {
    return {
      viewHeight: 0,
      isBg: false,
      ipChangeTimeId: null,
      SearchPages: ['/searchList'],
      PermissionRouterList: ['/literatureKing', '/pdfDetails'],
      setUseTimeId: null,
      waitTime: 0,
      isProduce: process.env.NODE_ENV === 'production'
    }
  },
  computed: {
    ...mapGetters(['token', 'userInfo', 'isNavBg'])
  },
  methods: {
    ...mapMutations('login', ['setNavBg']),
    ...mapActions('user', ['actionsGetUserInfo']),
    initJumpSource () {
      const source = this.$route.query.jumpSource
      if (source) {
        sessionStorage.setItem('jumpSource', source)
      }
    },
    mousedown () {
      if (!this.isProduce || !this.token) return
      if (this.waitTime >= 50) {
        this.submitUserUseTime()
        this.waitTime = 0
      }
      if (!this.setUseTimeId) {
        localStorage.setItem('useStartTime', new Date().getTime())
        this.setRegularTime()
      }
    },
    // 滚动条事件
    handleScroll () {
      const scrollTop = document.documentElement.scrollTop
      if (scrollTop > this.viewHeight) {
        this.isBg = true
      } else {
        this.isBg = false
      }
    },
    // 关闭搜索建议
    hideSearchProposal () {
      const SearchProposalBox = this.$refs.heightSearch
      if (SearchProposalBox && SearchProposalBox.isSearchProposal) {
        SearchProposalBox.isSearchProposal = false
      }
    },
    // 搜索按钮暴漏事件
    Jump (val) {
      if (this.$route.path === '/searchList') {
        if (val !== this.$route.query.val) {
          this.$myCount('PC-搜索文献')
          this.$router.push({
            query: merge(this.$route.query, { val: val })
          })
        }
      } else {
        this.$myCount('PC-搜索文献')
        this.$router.push({ path: '/searchList', query: { val } })
      }
    },
    listenScroll () {
      this.viewHeight = document.documentElement.clientHeight - 52
      document.addEventListener('scroll', this.handleScroll)
    },
    listenLogin () {
      // 监听页面是否在前台
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          if (this.isProduce && this.token) {
            this.submitUserUseTime(this.setRegularTime)
          }
          // 已登录需要更新vuex
          if (!this.token && localStorage.getItem('userToken')) {
            const token = localStorage.getItem('userToken')
            this.actionsGetUserInfo(token)
            // alert('已登录需要更新vuex')
          }
          // 用户退出需要清空vuex
          if (this.token && !localStorage.getItem('userToken')) {
            this.$store.commit('user/removeToken')
            // console.log(this.$route.path)
            if (guard.includes(this.$route.path) || this.$route.path === '/literatureKing') {
              window.location.reload()
            }
            // alert('退出需要清空vuex')
          }
        } else {
          if (this.isProduce && this.token) {
            this.removeUseTime()
            localStorage.setItem('useEndTime', new Date().getTime())
          }
        }
      })
    },
    ipChangeCallback () {
      if (this.ipChangeTimeId) {
        clearTimeout(this.ipChangeTimeId)
        this.ipChangeTimeId = null
      }
      this.ipChangeTimeId = setTimeout(async () => {
        const res = await this.$store.dispatch('user/getUserIpInfo')
        if (res.data?.partnerId) {
          const h = this.$createElement
          this.$notify({
            title: '系统提示',
            message: h('p', {},
              [
                '当前网络已通过【',
                h('span', { style: 'color:#4b639f;fontWeight:600' }, res.data.partnerName),
                '】IP认证，可畅享本站所有权益'
              ]
            ),
            type: 'success',
            showClose: false,
            offset: 40
          })
        }
        clearTimeout(this.ipChangeTimeId)
        this.ipChangeTimeId = null
      }, 500)
    },
    async submitUserUseTime (fn, catchBack) {
      // if (!this.token) return
      if (!this.token) {
        localStorage.removeItem('useStartTime')
        localStorage.removeItem('useEndTime')
        return
      }
      try {
        const startTime = localStorage.getItem('useStartTime')
        const endTime = localStorage.getItem('useEndTime')
        if (!startTime) {
          localStorage.setItem('useStartTime', new Date().getTime())
          return fn && fn()
        }
        const difference = Math.round((endTime - startTime) / 1000)
        if (difference < 1) {
          localStorage.setItem('useStartTime', new Date().getTime())
          return fn && fn()
        }
        // console.log(difference, '更新time')
        await submitUserUseTime({
          usedTime: difference > 360 ? 360 : difference
        })
        localStorage.setItem('useStartTime', new Date().getTime())
        localStorage.setItem('useEndTime', new Date().getTime())
        fn && fn()
      } catch (error) {
        localStorage.setItem('useStartTime', new Date().getTime())
        catchBack && catchBack()
      }
    },
    removeUseTime () {
      this.waitTime = 0
      if (this.setUseTimeId) {
        clearInterval(this.setUseTimeId)
        this.setUseTimeId = null
      }
    },
    setRegularTime () {
      this.removeUseTime()
      if (!this.token) return
      this.setUseTimeId = setInterval(() => {
        if (!this.token) {
          return this.removeUseTime()
        }
        if (this.waitTime === 60) {
          this.removeUseTime()
          this.submitUserUseTime()
          return
        }
        this.waitTime++
        localStorage.setItem('useEndTime', new Date().getTime())
      }, 5000)
    }
  },
  mounted () {

  },
  created () {
    window.addEventListener('online', this.ipChangeCallback)
    if (this.token) {
      this.actionsGetUserInfo(this.token)
      if (this.isProduce) {
        this.submitUserUseTime(this.setRegularTime)
      }
    } else {
      localStorage.removeItem('useStartTime')
      localStorage.removeItem('useEndTime')
    }
    this.$store.dispatch('user/getUserIpInfo')
    this.initJumpSource()
    this.listenScroll()
    this.listenLogin()
  },
  watch: {
    $route (to, from) {
      if (to.path === '/') {
        this.setNavBg(false)
        this.isBg = false
        document.addEventListener('scroll', this.handleScroll)
      } else {
        this.setNavBg(true)
        document.removeEventListener('scroll', this.handleScroll)
        this.isBg = true
      }
    }
  }
}
</script>
<style lang="less">
#app {
  width: 100%;
  min-height: 100%;
}

.index_top {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.home_fixed {
  position: fixed;
}

.isBg {
  background: url('./assets/img/index_title/2.gif') no-repeat;
  background-size: 100%;
  // background-color: #fff;
}
</style>
